<template>
  <b-card
    no-body
  >
    <b-card-body style="">
      <b-row v-if="loading" style="height: 200px;" class="text-center">
        <b-col
          sm="12"
          align-self="center"
        >
          <i2-data-loading/>
        </b-col>
      </b-row>
      <bar-chart v-if="!loading"
                 :horizontal="false" height="200"
                 :options="{xAxysGrid: false, yAxysGrid:false, dataLabels: false}"
                 :values="series" title="Média de Números por Participante"
      />
      <b-row v-if="totalPages > 1" class="align-items-center">
        <b-col md="5" class="text-right">
          <i2-icon-button
            icon="ChevronLeftIcon"
            variant="outline-primary"
            @click="previousPage"
          />
        </b-col>
        <b-col md="2" class="text-center">
          {{ actualPage }} / {{ totalPages }}
        </b-col>
        <b-col md="5" class="text-left">
          <i2-icon-button
            icon="ChevronRightIcon"
            variant="outline-primary"
            @click="nextPage"
          />
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import BarChart from '@/components/i2/Graph/BarChart.vue'
import I2DataLoading from '@/components/i2/layout/loading/I2DataLoading'

export default {
  name: 'ChartMedianNumbersByPerson',
  components: {
    BarChart,
    I2DataLoading,
  },
  data() {
    return {
      totalPerPage: 10,
      dates: [],
      initialValue: [],
      loading: false,
      series: {},
      actualPage: 1,
      totalPages: 1,
      allSeries: {},
      seriesPerPage: [],
    }
  },
  created() {
    this.getChartData()
  },
  methods: {
    getChartData() {
      this.loading = true
      this.$http.showLoader = false
      this.$http.get('/participants/dashboard/median-numbers')
        .then(response => {
          this.loading = false
          this.$http.showLoader = true
          this.allSeries = response
          this.preparePages()
        })
    },
    nextPage() {
      if ((this.actualPage + 1) > this.totalPages) {
        return
      }
      this.actualPage += 1
      this.series = this.seriesPerPage[this.actualPage]
    },
    previousPage() {
      if ((this.actualPage - 1) === 0) {
        return
      }
      this.actualPage -= 1
      this.series = this.seriesPerPage[this.actualPage]
    },
    preparePages() {
      const totalColumns = this.allSeries.labels.length
      const totalPages = Math.ceil(totalColumns / this.totalPerPage)
      this.totalPages = totalPages
      if (totalPages === 1) {
        this.series = this.allSeries
        return
      }
      for (let page = 1; page <= totalPages; page++) {
        if (!this.seriesPerPage[page]) {
          this.seriesPerPage[page] = {
            labels: [],
            series: [{
              color: this.allSeries.series[0].color,
              data: [],
            }],
          }
        }
      }

      let totalAdded = 0
      let currentPage = 1
      this.allSeries.labels.forEach((label, index) => {
        this.seriesPerPage[currentPage].labels.push(label)
        const currentSerie = this.allSeries.series[0].data[index]
        this.seriesPerPage[currentPage].series[0].data.push(currentSerie)

        totalAdded += 1
        if (totalAdded >= this.totalPerPage) {
          totalAdded = 0
          currentPage += 1
        }
      })
      this.series = this.seriesPerPage[1]
    },
  },
}
</script>
