<template>
  <b-card
    no-body
  >
    <b-row v-if="loading" style="height: 100%;" class="text-center">
      <b-col
        sm="12"
        align-self="center"
      >
        <i2-data-loading/></b-col>
    </b-row>
    <b-row v-if="!loading">

      <b-col
        sm="6"
        order-sm="1"
        order="2"
        class="d-flex justify-content-between flex-column mt-1 mt-sm-0"
      />

      <b-col
        sm="6"
        cols="12"
        order-sm="2"
        order="1"
        class="d-flex justify-content-between flex-column text-right"
      >
        <div>
          <b-dropdown
            :text="'Agrupado por: '+ defaultGroup.label"
            variant="transparent"
            class="chart-dropdown"
            right
            no-caret
            size="sm"
          >
            <b-dropdown-item
              v-for="group in groupBy"
              :key="group.value"
              @click="setGroupBy(group)"
            >
              {{ group.label }}
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </b-col>
    </b-row>
    <bar-chart v-if="!loading"
               :horizontal="true" height="200"
               :options="{xAxysGrid: false, yAxysGrid:false, dataLabels: false}"
               :values="series" title="Participantes Por Localização"
    />
  </b-card>
</template>

<script>
import BarChart from '@/components/i2/Graph/BarChart.vue'
import I2DataLoading from '@/components/i2/layout/loading/I2DataLoading'

export default {
  name: 'ChartBarParticipantPerState',
  components: {
    I2DataLoading,
    BarChart,
  },
  data() {
    return {
      dates: [],
      initialValue: [],
      loading: true,
      defaultGroup: null,
      series: {},
      groupBy: [
        { value: 'state', label: 'Estado' },
        { value: 'city', label: 'Cidade' },
      ],
    }
  },
  created() {
    const defaultGroup = window.localStorage.getItem('participant_state_group_value') || 'state'
    this.defaultGroup = this.groupBy.find(item => item.value === defaultGroup)
    this.getChartData()
  },
  methods: {
    setGroupBy(group) {
      this.defaultGroup = group
      window.localStorage.setItem('participant_state_group_value', group.value)
      this.getChartData()
    },
    getChartData() {
      this.loading = true
      this.$http.showLoader = false
      this.$http.get(`/participants/dashboard/total-by-state?group=${this.defaultGroup.value}`)
        .then(response => {
          this.loading = false
          this.$http.showLoader = true
          this.series = response
        })
    },
  },
}
</script>

<style scoped>

</style>
