<template>
  <b-card
    no-body
  >
    <b-card-body v-if="!isSinglePrizeDraw" style="" >
      <b-row v-if="loading" style="height: 100%;" class="text-center">
        <b-col
          sm="12"
          align-self="center"
        >
          <i2-data-loading/></b-col>
      </b-row>
      <bar-chart v-if="!loading"
                 :horizontal="false" height="200"
                 :options="{xAxysGrid: false, yAxysGrid:false, dataLabels: false}"
                 :values="series" title="Participantes Por Sorteio"
      />
    </b-card-body>
    <b-card-body v-if="isSinglePrizeDraw === true">
      <b-card-header>
        <b-card-title>Participantes</b-card-title>
      </b-card-header>
      <b-row>
        <b-col class="text-center participants-total mt-2">
          {{ totalParticipants }}
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import I2DataLoading from '@/components/i2/layout/loading/I2DataLoading'
import BarChart from '@/components/i2/Graph/BarChart.vue'

export default {
  name: 'ChartBarParticipantPerOrigin',
  components: {
    BarChart,
    I2DataLoading,
  },
  data() {
    return {
      dates: [],
      initialValue: [],
      colors: ['#45AEDC'],
      loading: false,
      series: {},
      chartOptions: {},
      isSinglePrizeDraw: false,
      totalParticipants: 0,
    }
  },
  created() {
    this.getChartData()
  },
  methods: {
    getChartData() {
      this.loading = true
      this.$http.showLoader = false
      this.$http.get('/participants/dashboard/total-by-origin')
        .then(response => {
          this.loading = false
          this.$http.showLoader = true
          this.series = response

          if (response.series[0].data.length === 1) {
            this.isSinglePrizeDraw = true
            this.totalParticipants = response.series[0].data[0]
          }
        })
    },
  },
}
</script>

<style scoped>

.participants-total {
  font-size: 90px;
  color: #45AEDC;
}

</style>
