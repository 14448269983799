<template>
  <b-modal
    v-model="showModal"
    title="Importações da Promoção"
    cancel-title="Fechar"
    no-close-on-backdrop
    ok-title="Fechar"
    size="xl"
    scrollable
    ok-only
    @change="onHide"
  >
    <Grid
      :fields="fields"
      :items="items"
      label="Sorteios"
      edit-button-label="edit"
      new-button-label="new"
      :show-search="false"
      @onChangePage="getHistory"
    >

      <template
        #cell(action)="data"
        style="width: 30%"
      >
        <div class="">

          <i2-icon-button
            icon="DownloadCloudIcon"
            class="mr-1"
            variant="outline-info"
            @click="downloadFile(data.item)"
          />

        </div>

      </template>
      <template
        #cell(total_numbers)="data"
        style="width: 30%"
      >
        {{ new Intl.NumberFormat('pt-BR').format(data.item.total_numbers) }}
      </template>
      <template
        #cell(total_lines)="data"
        style="width: 30%"
      >
        {{ new Intl.NumberFormat('pt-BR').format(data.item.total_lines) }}
      </template>
      <template
        #cell(person_success)="data"
        style="width: 30%"
      >
        {{ new Intl.NumberFormat('pt-BR').format(data.item.person_success) }}
      </template>
      <template
        #cell(person_error)="data"
        style="width: 30%"
      >
        {{ new Intl.NumberFormat('pt-BR').format(data.item.person_error) }}
      </template>
    </Grid>
  </b-modal>
</template>
<script>
export default {
  name: 'PromotionImportLogModal',
  props: {
    promotionUuid: {
      type: [String],
      required: true,
      default: '',
    },
    show: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      showModal: false,
      fields: [
        {
          key: 'created_at',
          label: 'Data',
          class: 'w-20',
          thClass: 'text-center',
          tdClass: 'text-center',
          sortable: true,
        },
        {
          key: 'original_filename',
          label: 'Arquivo',
          class: 'w-20',
          thClass: 'text-center',
          tdClass: 'text-center',
          sortable: true,
        },
        {
          key: 'user.name',
          label: 'Enviado por',
          class: 'w-20',
          thClass: 'text-center',
          tdClass: 'text-center',
          sortable: true,
        },
        {
          key: 'total_lines',
          label: 'Linhas',
          class: 'w-10',
          thClass: 'text-center',
          tdClass: 'text-center',
          sortable: true,
        },
        {
          key: 'person_success',
          label: 'Sucessos',
          class: 'w-10',
          thClass: 'text-center',
          tdClass: 'text-center',
          sortable: true,
        },
        {
          key: 'person_error',
          label: 'Erros',
          class: 'w-10',
          thClass: 'text-center',
          tdClass: 'text-center',
          sortable: true,
        },
        {
          key: 'total_numbers',
          label: 'Números',
          class: 'w-10',
          thClass: 'text-center',
          tdClass: 'text-center',
          sortable: true,
        },
        {
          key: 'action',
          label: 'Ações',
          class: 'w-10',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ],
      items: [],
    }
  },
  watch: {
    show(value) {
      this.showModal = value
      if (value) {
        this.getHistory()
      }
    },
  },
  mounted() {
    this.getHistory()
  },
  methods: {
    async getHistory(page) {
      if (this.promotionUuid === '') {
        return
      }
      const route = `promotions/${this.promotionUuid}/integration-history`
      this.items = await this.$search.get(
        route,
        page,
        this.search,
        '',
      )
    },
    onHide() {
      this.$emit('onClose', true)
    },

    downloadFile(file) {
      window.open(file.url_download_log, '_blank')
    },
  },
}
</script>

<style scoped>

</style>
